import {
  Cushion,
  FlexContainer,
  MaxWidth,
  Rectangle,
  Shadow,
  Typography,
} from '@pitchero/react-ui';
import TEAM_PROP_TYPES from 'lib/prop-types/team';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import SecondaryNavigationLinks from '../navigation/secondary-navigation-links';

const TeamNavigation = ({
  activeSection,
  team: { id: teamId, name: teamName, navigationItems },
}) => {
  const navigationTabs = () => {
    if (isEmpty(navigationItems)) {
      return null;
    }
    const links = ['homepage', ...navigationItems].map((item) => ({
      name: item,
      route: `team.${item}`,
      params: { teamId },
      transKey: `teams:navigation_${item}`,
    }));

    return <SecondaryNavigationLinks links={links} activeSection={activeSection} />;
  };

  return (
    <Shadow>
      <Rectangle fill="#fff">
        <Cushion
          responsive={[
            {
              maxWidth: 'tab',
              props: { horizontal: 'small' },
            },
          ]}
          horizontal="large"
          component="div"
        >
          <MaxWidth component="div" maxWidth={1366} center>
            <FlexContainer alignItems="center">
              <Cushion vertical="medium" right="xlarge">
                <Typography
                  className="team-navigation__title"
                  preset="title"
                  color="black"
                  responsive={[{ maxWidth: 'lap', props: { align: 'center' } }]}
                >
                  {teamName}
                </Typography>
              </Cushion>
              {navigationTabs()}
            </FlexContainer>
          </MaxWidth>
        </Cushion>
      </Rectangle>
    </Shadow>
  );
};

TeamNavigation.propTypes = {
  activeSection: PropTypes.string.isRequired,
  team: TEAM_PROP_TYPES.isRequired,
};

export default TeamNavigation;
