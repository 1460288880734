import React from 'react';
import { map } from 'lodash';
import { Grid, MaxWidth, Typography } from '@pitchero/react-ui';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { Trans } from 'lib/i18n';
import ClubLink from 'components/club-link';
import { getFooterNavItems } from 'components/club-frame/header/nav-items';

const Navigation = ({ club }) => {
  const navItems = getFooterNavItems(club);
  return (
    <MaxWidth
      component="div"
      maxWidth={500}
      className="footer-nav"
      center
      style={{ width: '100%' }}
    >
      <Grid
        columns="repeat(2, minmax(0, 1fr))"
        rowGap="medium"
        justifyItems="center"
        style={{ width: '100%' }}
      >
        {map(navItems, ({ id, routeParams, transKey }) => {
          return (
            <div key={id}>
              <ClubLink currentClub={club} {...routeParams}>
                <a>
                  <Typography preset="name" size={14} weight="normal" color="primaryContrast">
                    <Trans i18nKey={transKey}>{transKey}</Trans>
                  </Typography>
                </a>
              </ClubLink>
            </div>
          );
        })}
      </Grid>
    </MaxWidth>
  );
};

Navigation.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
};

export default Navigation;
