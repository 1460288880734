import {
  Cushion,
  FlexContainer,
  Grid,
  MaxWidth,
  Rectangle,
  Space,
  Typography,
} from '@pitchero/react-ui';
import IconWithTheme from 'components/icon-with-theme';
import PitcheroLogo from 'components/pitchero-logo';
import ArrowDownIcon from 'icons/ArrowDown';
import { Trans, i18n } from 'lib/i18n';
import { languages } from 'lib/i18n/languages';
import useBreakpoint from 'lib/use-breakpoint';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import Address from './address';
import LanguageSelectorPanel from './language-selector-panel';
import Navigation from './navigation';
import SEONavigation from './seo-navigation';

const BorderedItem = styled.div`
  width: 100%;
  border-color: rgba(255, 255, 255, 0.3);
  border-style: solid;
  border-width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) => {
    return `
      padding: ${props.isMobile ? '20px 0 ' : '0 20px'};
      border-top-width: ${props.isMobile ? '1px' : '0'};
      border-bottom-width: ${props.isMobile ? '1px' : '0'};
      border-left-width: ${props.isMobile ? '0' : '1px'};
    `;
  }}
`;

const PitcheroFooter = ({ sport }) => {
  const [isLanguagePanelOpen, setIsLanguagePanelOpen] = useState(false);
  const currentLanguage = find(languages, { code: i18n.language });
  const sporturl = sport.replace('_', '-');
  const sportname = sport.replace('_', ' ');
  const isMobile = useBreakpoint();

  const languageSelector = (
    <Typography
      className="footer-translate"
      preset="button"
      color="#fff"
      component="button"
      onClick={() => setIsLanguagePanelOpen(true)}
      style={{ width: 'fit-content' }}
    >
      <FlexContainer
        alignItems="center"
        style={{ backgroundColor: '#333', borderRadius: '100px', padding: '5px 15px' }}
      >
        {currentLanguage && currentLanguage.name}
        <Cushion left="xsmall">
          <IconWithTheme>
            <ArrowDownIcon size={12} />
          </IconWithTheme>
        </Cushion>
      </FlexContainer>
    </Typography>
  );

  return (
    <Rectangle fill="#000" component="div" style={{ paddingBottom: isMobile ? '20px' : '0' }}>
      <MaxWidth maxWidth={1366} center component="div">
        <Grid
          columns={isMobile ? '1fr' : '33% 67%'}
          alignItems="center"
          style={{ padding: '30px 40px', gap: isMobile ? '20px' : '0' }}
        >
          <FlexContainer flexDirection="column" alignItems="center" style={{ gap: '10px' }}>
            <Typography component="div" align="center">
              <Space bottom="xsmall">
                <Typography preset="tag--small" component="div" color="#fff">
                  <Trans i18nKey="common:powered_by">Powered by</Trans>
                </Typography>
              </Space>
              <a href="https://www.pitchero.com" aria-label="Pitchero Link" title="Pitchero">
                <PitcheroLogo width={100} color="#fff" />
              </a>
            </Typography>
            <Typography
              className="footer-pitchero-description"
              preset="subtitle--small"
              weight="normal"
              color="#fff"
              align="left"
              component="div"
            >
              <a
                href={`https://www.pitchero.com/club-website/features/${sporturl}?utm_source=club&utm_medium=footer`}
              >
                <Trans i18nKey="common:pitchero_description" values={{ sportname }}>
                  Pitchero provides everything you need to run your club online.
                </Trans>
              </a>
              <a href="https://www.pitchero.com/pricing?utm_source=club&utm_medium=footer">
                <p>
                  <Trans i18nKey="common:footer_trial">Start your free 30-day trial</Trans>
                </p>
              </a>
              <a href="https://blog.pitchero.com?utm_source=club&utm_medium=footer">
                <p>
                  <Trans i18nKey="common:footer_blog">Visit the Pitchero Blog</Trans>
                </p>
              </a>
            </Typography>
          </FlexContainer>
          <BorderedItem isMobile={isMobile}>
            <Navigation color="#fff" isMobile={isMobile} />
            {!isMobile && <>{languageSelector}</>}
          </BorderedItem>
        </Grid>
      </MaxWidth>
      {!isMobile && <SEONavigation color="#fff" />}
      <Address isMobile={isMobile} />
      {isMobile && (
        <FlexContainer justifyContent="center" style={{ margin: '10px 0' }}>
          {languageSelector}
        </FlexContainer>
      )}
      <LanguageSelectorPanel
        isOpen={isLanguagePanelOpen}
        onClose={() => setIsLanguagePanelOpen(false)}
      />
    </Rectangle>
  );
};

PitcheroFooter.propTypes = {
  sport: PropTypes.string,
};

PitcheroFooter.defaultProps = {
  sport: '',
};

PitcheroFooter.displayName = 'PitcheroFooter';

export default React.memo(PitcheroFooter);
