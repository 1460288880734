import TEAM_PROP_TYPES from 'lib/prop-types/team';
import PropTypes from 'prop-types';
import React from 'react';
import SlashNavigation from '../navigation/mobile/slash-navigation';

const buildItems = (team) => {
  const output = ['homepage', ...team.navigationItems].map((item) => ({
    name: item,
    route: `team.${item}`,
    params: { teamId: team.id },
    transKey: `teams:navigation_${item}`,
  }));
  return output;
};

const MobileNavigation = ({ team, activeSection }) => {
  return (
    <SlashNavigation
      name={team.name}
      activeSection={activeSection}
      activeSectionTransKey={`teams:navigation_${activeSection}`}
      items={buildItems(team)}
    />
  );
};

MobileNavigation.propTypes = {
  activeSection: PropTypes.string.isRequired,
  team: TEAM_PROP_TYPES.isRequired,
};

export default MobileNavigation;
