import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';

const PromotedLinks = ({ links }) => {
  return (
    <Cushion top="medium" horizontal="medium" component="div">
      <Typography preset="navItem--small" color="white" transform="uppercase">
        <Trans i18nKey="common:club_links">Club Links</Trans>
      </Typography>
      {links.map((link) => (
        <Rectangle key={link.id}>
          <Cushion top="small" component="div">
            <Typography
              color="lightgrey"
              component="a"
              href={link.url}
              target={link.target}
              rel="noopener norefferer"
              transform="uppercase"
              size="0.75rem"
            >
              {link.label}
            </Typography>
          </Cushion>
        </Rectangle>
      ))}
    </Cushion>
  );
};

PromotedLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.string,
    }),
  ).isRequired,
};
export default PromotedLinks;
