import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, FlexContainer, Grid, Space, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';

const utmLink = '?utm_source=club&utm_medium=footer';

const navItems = [
  {
    transKey: 'help_centre',
    url: 'https://help.pitchero.com/knowledge',
  },
  { transKey: 'about', url: 'https://join.pitchero.com/about' },
  {
    transKey: 'advertising',
    url: 'https://join.pitchero.com/advertise',
  },
  { transKey: 'careers', url: 'https://apply.workable.com/pitchero/' },
  { transKey: 'technology', url: 'https://www.pitchero.com/technology' },
  { transKey: 'gdpr', url: 'https://www.pitchero.com/gdpr' },
  { transKey: 'terms_of_service', url: 'https://www.pitchero.com/terms-of-service' },
  { transKey: 'privacy_policy', url: 'https://www.pitchero.com/privacy-policy' },
  { transKey: 'cookie_policy', url: 'https://www.pitchero.com/cookie-policy' },
  {
    transKey: 'manage_consents',
    url: '#',
    onClick: function onClick(event) {
      event.preventDefault();

      // __tcfapi is the IAB content framework used by our ad provider, see here:
      // https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework

      // has loaded the consent manager
      // eslint-disable-next-line no-underscore-dangle
      const hasCmpConsentManagerFromVendor = typeof window.__tcfapi === 'function';

      // has loaded the pitchero.com cookie consent
      // eslint-disable-next-line no-undef
      const hasPitcheroCookieConsent = typeof cookieConsent !== 'undefined';

      // if we have the consent manager, show this on the page,
      // otherwise, show the pitchero consent manager
      if (hasCmpConsentManagerFromVendor) {
        // eslint-disable-next-line no-underscore-dangle
        window.__tcfapi('openunic');
      }

      if (hasPitcheroCookieConsent) {
        // eslint-disable-next-line no-undef
        showCookieConsentSettings();
      }
    },
  },
];

const Navigation = ({ color, isMobile }) => {
  const items = navItems.map(({ onClick, transKey, url, id }) => (
    <Space bottom="small" key={transKey}>
      <Cushion horizontal="small" vertical="small">
        <Typography
          component="a"
          preset="description"
          size={14}
          color={color}
          href={url.length > 1 ? `${url}${utmLink}` : url}
          id={id}
          target="_blank"
          rel={transKey === 'careers' ? 'noopener' : ''}
          onClick={(event) => {
            if (onClick) {
              onClick(event);
            }
          }}
          style={{ whiteSpace: 'nowrap', display: 'inline-block' }}
        >
          <Trans i18nKey={`common:footer_${transKey}`}>{transKey}</Trans>
        </Typography>
      </Cushion>
    </Space>
  ));
  return (
    <Typography
      align="center"
      component="div"
      responsive={[{ minWidth: 'tab', props: { align: 'left' } }]}
    >
      {isMobile ? (
        <Grid columns="1fr 1fr" columnGap="medium" alignItems="center">
          {items}
        </Grid>
      ) : (
        <FlexContainer flexWrap="wrap">{items}</FlexContainer>
      )}
    </Typography>
  );
};

Navigation.propTypes = {
  color: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default Navigation;
