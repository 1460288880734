import { Cushion, Grid, MaxWidth, Rectangle } from '@pitchero/react-ui';
import Image from 'components/basic-image';
import ClubLink from 'components/club-link';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import TEAM_PROP_TYPES from 'lib/prop-types/team';
import useBreakpoint from 'lib/use-breakpoint';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import LoadingIndicator from './loading-indicator';
import LoginBox from './login';
import NavPanel from './nav-panel';
import LoginPanel from './nav-panel/login-panel';
import PromotedLinks from './promoted-links';
import ClubRanking from './ranking';
import SponsorStrip from './sponsor-strip';
import TitleBar from './title-bar';

const Header = ({ activeSection, club, pageType, team, appAdBannerClosed }) => {
  const initialNavPanelLevelOne = { isOpen: false, props: {} };
  const initialNavLevelLogin = { isOpen: false, props: {} };
  const [navPanelLevelOne, setNavPanelLevelOne] = useState(initialNavPanelLevelOne);
  const [navPanelLogin, setNavPanelLogin] = useState(initialNavLevelLogin);

  const { facebookPage, promotedLinks, twitterAccount, instagramAccount, rank, rankGroup } = club;
  const headerSponsors = club.sponsors.filter((sponsor) => sponsor.placements.includes('header'));

  // This is used to position the nav panel correctly, so it is underneath
  // the page header, and app advert if it is present
  const containerRef = useRef(null);
  const [navOffsetHeight, setNavOffsetHeight] = useState(0);
  const isMobile = useBreakpoint();
  useEffect(() => {
    setNavOffsetHeight(containerRef.current.offsetTop + containerRef.current.clientHeight - 1);
  }, [containerRef, appAdBannerClosed, isMobile]);

  const closeAllPanels = () => {
    setNavPanelLevelOne(initialNavPanelLevelOne);
    setNavPanelLogin(initialNavLevelLogin);
  };

  return (
    <>
      <LoadingIndicator />
      <SponsorStrip
        pageType={pageType}
        leagueSponsor={club.leagueSponsor}
        sponsors={headerSponsors}
        team={team}
      />

      {!isMobile && (
        <PromotedLinks
          facebookPage={facebookPage}
          links={promotedLinks}
          twitterAccount={twitterAccount}
          instagramAccount={instagramAccount}
        >
          <ClubRanking rank={rank} rankGroup={rankGroup} />
        </PromotedLinks>
      )}
      <Cushion responsive={[{ minWidth: 'fullHeader', props: { horizontal: 'medium' } }]}>
        <MaxWidth maxWidth={1366} center>
          <Rectangle
            fill="headerBg"
            responsive={[{ minWidth: 'fullHeader', props: { fill: 'transparent' } }]}
            component="div"
          >
            <div ref={containerRef}>
              <Cushion responsive={[{ minWidth: 'fullHeader', props: { vertical: 'medium' } }]}>
                <Grid
                  className="club-header"
                  columns="80px minmax(0, 1fr)"
                  alignItems="center"
                  responsive={[
                    { minWidth: 'fullHeader', props: { columns: '110px  minmax(0, 1fr)' } },
                  ]}
                >
                  <div className="club-header__logo">
                    <ClubLink currentClub={club} route="homepage">
                      <a>
                        <Cushion
                          component="div"
                          horizontal="small"
                          vertical="small"
                          style={{ display: 'flex' }}
                          responsive={[
                            {
                              minWidth: 'fullHeader',
                              props: { left: '0', right: 'small', vertical: '0' },
                            },
                          ]}
                        >
                          <Image
                            src={club.logo}
                            alt={club.name}
                            height={90}
                            width={90}
                            includeRetina
                            transformation="square"
                          />
                        </Cushion>
                      </a>
                    </ClubLink>
                  </div>
                  <div className="clubHeader__content">
                    <Cushion
                      all="small"
                      left="0px"
                      responsive={[
                        {
                          minWidth: 'fullHeader',
                          props: { left: 'xlarge', top: 'large', bottom: '0' },
                        },
                      ]}
                    >
                      <TitleBar
                        club={club}
                        showMobileTitle={navPanelLevelOne.isOpen || navPanelLogin.isOpen}
                        isMobile={isMobile}
                        activeSection={activeSection}
                      >
                        <LoginBox
                          club={club}
                          onMenuClick={() =>
                            setNavPanelLevelOne({ ...navPanelLevelOne, isOpen: true })
                          }
                          isMenuOpen={navPanelLevelOne.isOpen}
                          isLoginOpen={navPanelLogin.isOpen}
                          openMobileLoginPanel={() =>
                            setNavPanelLogin({ ...initialNavLevelLogin, isOpen: true })
                          }
                        />
                      </TitleBar>
                    </Cushion>
                  </div>
                </Grid>
              </Cushion>
            </div>
          </Rectangle>
        </MaxWidth>
      </Cushion>
      <NavPanel
        isOpen={navPanelLevelOne.isOpen}
        onClose={closeAllPanels}
        club={club}
        activeSection={activeSection}
        navOffsetHeight={navOffsetHeight}
        openLoginPanel={() => setNavPanelLogin({ ...initialNavLevelLogin, isOpen: true })}
      />
      <LoginPanel
        {...navPanelLogin}
        navOffsetHeight={navOffsetHeight}
        order={2}
        onClose={closeAllPanels}
        onBackClick={() => setNavPanelLogin(initialNavLevelLogin)}
      />
    </>
  );
};

Header.propTypes = {
  activeSection: PropTypes.string,
  club: CLUB_PROP_TYPES.isRequired,
  pageType: PropTypes.string,
  team: TEAM_PROP_TYPES,
  appAdBannerClosed: PropTypes.bool,
};
Header.defaultProps = {
  activeSection: '',
  pageType: '',
  team: null,
  appAdBannerClosed: false,
};
export default Header;
