import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Space, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';

const utmLink = '?utm_source=club&utm_medium=footer';

const seoNavItems = [
  {
    trans: 'Free Website For Sports Clubs',
    url: 'https://join.pitchero.com/free-website-for-sports-clubs',
  },
  {
    trans: 'Football Club Website',
    url: 'https://join.pitchero.com/website-for-football-club',
  },
  {
    trans: 'Cricket Club Website',
    url: 'https://join.pitchero.com/free-cricket-club-website',
  },
  {
    trans: 'Hockey Club Website',
    url: 'https://join.pitchero.com/free-website-for-hockey-clubs',
  },
  {
    trans: 'Rugby Club Website',
    url: 'https://join.pitchero.com/free-website-for-rugby-club',
  },
  {
    trans: 'Pitchero Club App',
    url: 'https://join.pitchero.com/club-app',
  },
  {
    trans: 'Pitchero Manager App',
    url: 'https://join.pitchero.com/manager-app',
  },
  {
    trans: 'Sports GPS Vest - Improve your performance with PitcheroGPS',
    url: 'https://www.pitcherogps.com',
  },
  {
    trans: 'Christmas Gift for Footballers',
    url:
      'https://priority.pitcherogps.com/christmas-gift-football?utm_source=pitchero&utm_medium=footer',
  },
  {
    trans: 'Christmas Gift for Rugby Players',
    url:
      'https://priority.pitcherogps.com/christmas-gift-rugby?utm_source=pitchero&utm_medium=footer',
  },
  {
    trans: 'Christmas Gift For Hockey Players',
    url:
      'https://priority.pitcherogps.com/christmas-gift-hockey?utm_source=pitchero&utm_medium=footer',
  },
];

const SEONavigation = ({ color }) => {
  return (
    <Typography align="center" component="div">
      {seoNavItems.map(({ onClick, trans, url }) => (
        <Space bottom="small" key={trans}>
          <Cushion horizontal="small" vertical="small">
            <Typography
              component="a"
              preset="description"
              color={color}
              target="_blank"
              href={`${url}${utmLink}`}
              onClick={(event) => {
                if (onClick) {
                  onClick(event);
                }
              }}
              style={{ display: 'inline-block' }}
            >
              <Trans>{trans}</Trans>
            </Typography>
          </Cushion>
        </Space>
      ))}
    </Typography>
  );
};

SEONavigation.propTypes = {
  color: PropTypes.string.isRequired,
};

export default SEONavigation;
