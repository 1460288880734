import { Cushion, Grid, Typography, withTheme } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';
import DROPDOWN_OPTIONS_PROPTYPES from 'lib/prop-types/dropdown-options';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Container from './container';
import Option from './option';
import Trigger from './trigger';
import useOutsideClick from './useOutsideClick';

const Dropdown = ({ options, groupedOptions, children, uiTheme, navBarLeftOffset }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  const [leftOffset, setLeftOffset] = useState(0);
  const triggerRef = useRef(null);

  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setShouldShow(false);
    setIsOpen(false);
  };

  const dropdownRef = useOutsideClick(() => {
    if (isOpen) {
      closeMenu();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setShouldShow(true);
    }
  }, [isOpen]);

  let dropdownStyle = {
    opacity: 0,
    zIndex: -1,
  };

  if (shouldShow) {
    dropdownStyle = {
      opacity: 1,
      zIndex: 100,
    };
  }

  useEffect(() => {
    const newLeftOffset = triggerRef.current
      ? triggerRef.current.getBoundingClientRect().left - navBarLeftOffset
      : null;
    if (leftOffset !== newLeftOffset) {
      setLeftOffset(newLeftOffset);
    }
  });
  return (
    <div style={{ position: 'relative' }}>
      <Trigger
        ref={triggerRef}
        onClick={() => (isOpen ? closeMenu() : openMenu())}
        isOpen={isOpen}
        uiTheme={uiTheme}
      >
        {children}
      </Trigger>
      {isOpen && (
        <div
          ref={dropdownRef}
          style={{
            position: 'relative',
            ...dropdownStyle,
          }}
        >
          <Container
            leftOffset={leftOffset}
            topAdjustment={0}
            fullWidth={!!groupedOptions}
            className={groupedOptions ? 'dropdown-container' : ''}
          >
            {options &&
              options.map((option) => (
                <Option key={option.id} {...option} onClick={() => closeMenu()}>
                  {option.transKey ? (
                    <Trans i18nKey={option.transKey}>{option.transKey}</Trans>
                  ) : (
                    option.name
                  )}
                </Option>
              ))}
            {groupedOptions && (
              <Grid
                columns="repeat(auto-fit, minmax(180px, 1fr))"
                columnGap="medium"
                rowGap="large"
              >
                {groupedOptions.map((group) => (
                  <div key={group.id}>
                    <Cushion left="small" bottom="medium">
                      <Typography
                        preset="staticSubtitle--large"
                        weight="bold"
                        color="black"
                        component="div"
                      >
                        {group.transKey ? (
                          <Trans i18nKey={group.transKey}>{group.transKey}</Trans>
                        ) : (
                          group.name
                        )}
                      </Typography>
                    </Cushion>
                    {group.options.map((option) => (
                      <Option key={option.id} {...option} onClick={() => setIsOpen(false)}>
                        {option.transKey ? (
                          <Trans i18nKey={option.transKey}>{option.transKey}</Trans>
                        ) : (
                          option.name
                        )}
                      </Option>
                    ))}
                  </div>
                ))}
              </Grid>
            )}
          </Container>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  options: DROPDOWN_OPTIONS_PROPTYPES,
  groupedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      transKey: PropTypes.string,
      id: PropTypes.string,
      options: DROPDOWN_OPTIONS_PROPTYPES,
    }),
  ),
  uiTheme: PropTypes.shape(),
  navBarLeftOffset: PropTypes.number.isRequired,
};
Dropdown.defaultProps = {
  children: null,
  options: null,
  groupedOptions: null,
  uiTheme: {},
};
export default withTheme(Dropdown);
