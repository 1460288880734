import { Typography } from '@pitchero/react-ui';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TriggerDiv = styled.div`
  outline: none;
  background-color: ${(props) => (props.isOpen ? '#fff' : 'transparent')};
  border-top-left-radius: ${(props) => (props.isOpen ? '5px' : '0')};
  border-top-right-radius: ${(props) => (props.isOpen ? '5px' : '0')};
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    text-decoration: ${(props) =>
      !props.isOpen ? `underline 4px ${props.headerContrast}` : 'none'};
    text-underline-offset: 10px;
  }
`;
const Trigger = React.forwardRef(({ onClick, children, isOpen, uiTheme }, ref) => {
  const headerContrast = get(uiTheme, 'colors.headerContrast', '#fff');
  return (
    <div>
      <TriggerDiv
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
        isOpen={isOpen}
        headerContrast={headerContrast}
        ref={ref}
      >
        <Typography preset="header--large" color={isOpen ? '#000' : 'headerContrast'}>
          {children}
        </Typography>
      </TriggerDiv>
    </div>
  );
});

Trigger.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  uiTheme: PropTypes.shape(),
};

Trigger.defaultProps = {
  isOpen: false,
  uiTheme: {},
};
export default Trigger;
