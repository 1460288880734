import {
  Cushion,
  FlexContainer,
  MaxWidth,
  Rectangle,
  ScrollBox,
  Show,
  Space,
  Typography,
} from '@pitchero/react-ui';
import IconWithTheme from 'components/icon-with-theme';
import FacebookIcon from 'icons/Facebook';
import InstagramIcon from 'icons/Instagram';
import TwitterIcon from 'icons/Twitter';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const PromotedLinks = ({ facebookPage, links, twitterAccount, instagramAccount, children }) => {
  return (
    <Show minWidth="fullHeader" component="div">
      <Rectangle fill="#000" component="div">
        <Cushion vertical={4} horizontal="medium">
          <MaxWidth maxWidth={1366} center>
            <FlexContainer alignItems="center" justifyContent="space-between">
              <FlexContainer alignItems="center" flexWrap="wrap">
                {facebookPage && (
                  <Space right="medium">
                    <a href={facebookPage} title="facebook" aria-label="Club Facebook">
                      <IconWithTheme>
                        <FacebookIcon color="#fff" size={18} topOffset={2} />
                      </IconWithTheme>
                    </a>
                  </Space>
                )}
                {twitterAccount && (
                  <Space right="medium">
                    <a
                      href={`https://twitter.com/${twitterAccount}`}
                      title="twitter"
                      aria-label="Club Twitter"
                    >
                      <IconWithTheme>
                        <TwitterIcon color="#fff" size={16} topOffset={2} viewBox="0 0 1200 1200" />
                      </IconWithTheme>
                    </a>
                  </Space>
                )}
                {instagramAccount && (
                  <Space right="medium">
                    <a href={instagramAccount} title="instagram" aria-label="Club Instagram">
                      <IconWithTheme>
                        <InstagramIcon color="#fff" size={18} topOffset={2} />
                      </IconWithTheme>
                    </a>
                  </Space>
                )}
                {!isEmpty(links) && (
                  <ScrollBox xOnly component="div">
                    <FlexContainer alignItems="center" flexWrap="wrap">
                      {links.map((link) => (
                        <Cushion right={36} key={link.id}>
                          <Typography
                            preset="header--small"
                            color="#fff"
                            component="a"
                            href={link.url}
                            target={link.target}
                            rel="noopener norefferer nofollow"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {link.label}
                          </Typography>
                        </Cushion>
                      ))}
                    </FlexContainer>
                  </ScrollBox>
                )}
              </FlexContainer>
              {children}
            </FlexContainer>
          </MaxWidth>
        </Cushion>
      </Rectangle>
    </Show>
  );
};

PromotedLinks.defaultProps = {
  facebookPage: null,
  links: [],
  twitterAccount: null,
  instagramAccount: null,
};

PromotedLinks.propTypes = {
  facebookPage: PropTypes.string,
  instagramAccount: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.string,
    }),
  ),
  twitterAccount: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default React.memo(PromotedLinks, () => true);
