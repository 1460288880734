import { Cushion, Panel, Rectangle, ScrollBox, Typography } from '@pitchero/react-ui';
import IconWithTheme from 'components/icon-with-theme';
import BackArrowIcon from 'icons/BackArrow';
import PropTypes from 'prop-types';
import React from 'react';
import LoginForm from '../../../auth/login-form';

const LoginPanel = ({ isOpen, onClose, onBackClick, order, navOffsetHeight }) => {
  return (
    <Panel
      isOpen={isOpen}
      onClose={onClose}
      order={order}
      style={{
        width: '100%',
        maxWidth: '100%',
        right: isOpen ? 0 : '-100%',
        backgroundColor: '#000',
        top: `${navOffsetHeight}px`,
      }}
      closeIconOptions={{
        style: { display: 'none' },
      }}
      lightsOutOpacity={0}
    >
      <ScrollBox yOnly>
        <Cushion top="large" bottom="xxlarge" left="medium" right="medium" component="div">
          <Rectangle>
            <Typography
              preset="name"
              color="white"
              fill="black"
              onClick={onBackClick}
              onKeyPress={onBackClick}
              aria-label="back"
              role="button"
              tabIndex={-1}
              size="large"
              style={{ cursor: 'pointer' }}
            >
              <IconWithTheme>
                <BackArrowIcon color="white" topOffset={2} />
              </IconWithTheme>
              <Cushion left="medium" component="span">
                Back
              </Cushion>
            </Typography>
          </Rectangle>
          <Cushion horizontal="large">
            <LoginForm isMobilePanel style={{ height: '700px' }} closeMobilePanel={onClose} />
          </Cushion>
        </Cushion>
      </ScrollBox>
    </Panel>
  );
};

LoginPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  order: PropTypes.number.isRequired,
  navOffsetHeight: PropTypes.number.isRequired,
};

export default LoginPanel;
