import { FlexContainer, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';
import PropTypes from 'prop-types';
import React from 'react';

const Address = ({ isMobile }) => {
  const endYear = new Date().getFullYear();

  return (
    <FlexContainer
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems="center"
      justifyContent="center"
      style={{
        gap: isMobile ? '5px' : '10px',
        padding: isMobile ? '0' : '10px',
        backgroundColor: isMobile ? '#000' : '#272727',
      }}
    >
      <Typography preset="description" color="footerBgContrast" component="span">
        &copy;
        <Trans i18nKey="common:footer_copyright" values={{ endYear }}>
          {`Pitch Hero Ltd 2008-${endYear}`}
        </Trans>
      </Typography>
      <Typography preset="description" color="footerBgContrast" align="center" component="span">
        <Trans i18nKey="common:footer_company_info">
          Pitch Hero Ltd Registered in ENGLAND | WF3 1DR | Company Number - 636 1033
        </Trans>
      </Typography>
      <Typography preset="description" color="footerBgContrast" component="span">
        <Trans i18nKey="common:footer_digital_advertiser">
          Digital Advertising by Planet Sport Group
        </Trans>
      </Typography>
    </FlexContainer>
  );
};

Address.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Address;
