import { Cushion, Typography, withTheme } from '@pitchero/react-ui';
import ClubLink from 'components/club-link';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  &:hover {
    background-color: ${(props) => props.hoverBgColor};
    transition: background-color 300ms ease-in-out;
  }
`;

const Option = ({ children, onClick, routeParams, uiTheme }) => {
  const hoverBgColor = get(uiTheme, 'colors.alabaster', '#E7E7E7');
  return (
    <ClubLink {...routeParams}>
      <a role="link" tabIndex={0} onClick={onClick} onKeyPress={onClick}>
        <Wrapper hoverBgColor={hoverBgColor}>
          <Cushion all="small">
            <Typography
              preset="subtitle--small"
              weight={500}
              color="dovegrey"
              component="div"
              style={{ whiteSpace: 'nowrap', minWidth: 140 }}
            >
              {children}
            </Typography>
          </Cushion>
        </Wrapper>
      </a>
    </ClubLink>
  );
};

Option.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  routeParams: PropTypes.shape(),
  uiTheme: PropTypes.shape(),
};
Option.defaultProps = {
  onClick: () => null,
  routeParams: {},
  uiTheme: {},
};
export default withTheme(Option);
