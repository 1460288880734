import React from 'react';
import PropTypes from 'prop-types';
import HeaderContext from './context';

const HeaderWrapper = ({ children, style }) => {
  return (
    <HeaderContext.Consumer>
      {({ backgroundColor, height }) => {
        const containerStyle = {
          backgroundColor,
          height,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: -1,
        };

        return (
          <header style={{ backgroundColor, ...style }}>
            {height > 0 && <div style={containerStyle} />}
            {children}
          </header>
        );
      }}
    </HeaderContext.Consumer>
  );
};

HeaderWrapper.defaultProps = {
  style: {},
};

HeaderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  style: PropTypes.shape(),
};

export default HeaderWrapper;
