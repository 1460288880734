import {
  FlexContainer,
  Grid,
  MaxWidth,
  Rectangle,
  Typography,
  withTheme,
} from '@pitchero/react-ui';
import Image from 'components/basic-image/lazy';
import { Trans } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import useBreakpoint from 'lib/use-breakpoint';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isLoggedIn } from 'store/auth/selectors';
import {
  isAssociated as isAssociatedSelector,
  isLoadingNetworkPanel,
} from 'store/membership/selectors';
import styled from 'styled-components';
import Location from './location';
import Login from './login';
import Navigation from './navigation';
import SocialIcon from './social-icon';

const BorderedItem = styled.div`
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.3);
  border-style: solid;
  border-width: 0;
  ${(props) => {
    return `
      height: ${props.isMobile ? 'auto' : '100%'};
      padding: ${props.isMobile ? '20px 0 ' : '0 20px'};
      border-top-width: ${props.isMobile ? '1px' : '0'};
      border-bottom-width: ${props.isMobile ? '1px' : '0'};
      border-left-width: ${props.isMobile ? '0' : '1px'};
      ${props.bothBorders ? `border-right-width: ${props.isMobile ? '0' : '1px'};` : ''}
    `;
  }}
`;

const ClubFooter = ({ club, loggedIn, isLoading, isAssociated: isAssociatedToClub }) => {
  const { facebookPage, logo, name, twitterAccount, instagramAccount } = club;
  const hasSocialAccounts = facebookPage || twitterAccount || instagramAccount;
  const isJoinShowing = !isLoading && (!loggedIn || !isAssociatedToClub);
  const hasThreeSections = hasSocialAccounts || isJoinShowing;

  const isMobile = useBreakpoint();

  const logoAndName = (
    <>
      <Image src={logo} alt={name} height={60} width={60} transformation="absolute" includeRetina />
      <Typography
        preset="title"
        color="primaryContrast"
        align={isMobile ? 'center' : 'left'}
        size={18}
      >
        {name}
      </Typography>
    </>
  );

  return (
    <Rectangle fill="primary" component="div">
      <MaxWidth maxWidth={1366} center component="div">
        <Grid
          columns="1fr"
          justifyItems="center"
          alignItems="center"
          style={{ padding: '30px 40px', gap: '20px' }}
          responsive={[
            {
              minWidth: 'fullHeader',
              props: {
                columns: `33% ${hasThreeSections ? '33% 33%' : '67%'}`,
              },
            },
          ]}
        >
          {club.location ? (
            <FlexContainer
              flexDirection="column"
              style={{ gap: '20px', paddingRight: isMobile ? '0' : '40px' }}
            >
              <FlexContainer
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems="center"
                style={{ gap: '20px' }}
              >
                {logoAndName}
              </FlexContainer>
              <Location location={club.location} isMobile={isMobile} />
            </FlexContainer>
          ) : (
            <FlexContainer flexDirection="column" alignItems="center" style={{ gap: '20px' }}>
              {logoAndName}
            </FlexContainer>
          )}
          <BorderedItem isMobile={isMobile} bothBorders={hasThreeSections}>
            <Navigation club={club} />
          </BorderedItem>
          {(hasSocialAccounts || isJoinShowing) && (
            <FlexContainer
              flexDirection="column"
              alignItems={isMobile ? 'center' : 'flex-start'}
              style={{ gap: '10px' }}
            >
              {hasSocialAccounts && (
                <>
                  <Typography preset="title" color="primaryContrast" size={14}>
                    <Trans i18nKey="common:follow_social_media">Follow Us</Trans>
                  </Typography>
                  <FlexContainer alignItems="center" style={{ gap: '20px' }}>
                    {facebookPage && <SocialIcon iconName="FACEBOOK" url={facebookPage} />}
                    {twitterAccount && (
                      <SocialIcon
                        iconName="TWITTER"
                        url={`https://twitter.com/${twitterAccount}`}
                      />
                    )}
                    {instagramAccount && <SocialIcon iconName="INSTAGRAM" url={instagramAccount} />}
                  </FlexContainer>
                </>
              )}
              {isJoinShowing && (
                <>
                  <Typography preset="title" color="primaryContrast" size={14}>
                    <Trans i18nKey="common:join_club_website">Join Us</Trans>
                  </Typography>
                  <Login
                    club={club}
                    loggedIn={loggedIn}
                    isLoading={isLoading}
                    isAssociated={isAssociatedToClub}
                  />
                </>
              )}
            </FlexContainer>
          )}
        </Grid>
      </MaxWidth>
    </Rectangle>
  );
};

ClubFooter.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  isAssociated: PropTypes.bool,
  isLoading: PropTypes.bool,
  loggedIn: PropTypes.bool,
};
ClubFooter.defaultProps = {
  loggedIn: false,
  isLoading: false,
  isAssociated: false,
};
const mapStateToProps = (state, { club: { id: clubId } }) => ({
  loggedIn: isLoggedIn(state),
  isLoading: isLoadingNetworkPanel(state, clubId),
  isAssociated: isAssociatedSelector(state, clubId),
});

const propsAreEqual = (prevProps, nextProps) => {
  const { club: oldClub, isLoading: wasLoading, isAssociated: wasAssociated } = prevProps;
  const { club: newClub, isLoading, isAssociated } = nextProps;
  return oldClub.id === newClub.id && wasLoading === isLoading && wasAssociated === isAssociated;
};

export default withTheme(connect(mapStateToProps)(React.memo(ClubFooter, propsAreEqual)));
