import IconWithTheme from 'components/icon-with-theme';
import FacebookIcon from 'icons/Facebook';
import InstagramIcon from 'icons/Instagram';
import TwitterIcon from 'icons/Twitter';
import PropTypes from 'prop-types';
import React from 'react';

function getIconComponent(iconName) {
  switch (iconName) {
    case 'FACEBOOK':
      return FacebookIcon;
    case 'INSTAGRAM':
      return InstagramIcon;
    default:
      return TwitterIcon;
  }
}

function getShareText(iconName) {
  switch (iconName) {
    case 'FACEBOOK':
      return 'Club Facebook';
    case 'INSTAGRAM':
      return 'Club Instagram';
    default:
      return 'Club Twitter';
  }
}

const SocialIcon = ({ iconName, url }) => {
  const IconComponent = getIconComponent(iconName);
  const isTwitter = iconName === 'TWITTER';

  return (
    <a href={url} aria-label={getShareText(iconName)} title={iconName.toLowerCase()}>
      <IconWithTheme>
        <IconComponent
          name={iconName}
          color="primaryContrast"
          size={isTwitter ? 16 : 20}
          style={{ verticalAlign: 'middle' }}
          viewBox={isTwitter ? '0 0 1200 1227' : undefined}
        />
      </IconWithTheme>
    </a>
  );
};

SocialIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
export default SocialIcon;
